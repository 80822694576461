const baseUrl1 = "https://c.api.quyixian32.com/api";
//http://32.api.53online.cn/api
export default {
  //登录
  login: baseUrl1 + "/Login/Index",

  //获取码与图书信息
  getCode: baseUrl1 + "/User/NewGetCodeInfo",

  //保存用户信息
  SaveInfo: baseUrl1 + "/User/SaveUserCode",

  //追溯
  getCodeAll: baseUrl1 + "/Admin/GetInfo",

  //保存图片OSS
  SaveFile: baseUrl1 + "/File/UploadFileOSS",

  //盗版信息表单
  SavePirate: baseUrl1 + "/User/SavePirateRecord",

  //获取地理位置
  wxAddress: baseUrl1 + "/Wx/GetAddress",
};
